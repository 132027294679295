import React, { useEffect, useState } from "react"
import Link from "gatsby-link"
import Nav from "../nav"

import Hamburger from "../buttons/hamburger"

const Header = () => {
  //  ? Responsiveness
  const [isOpen, setIsOpen] = useState(false)
  const handleHamburgerOnClick = () => {
    return setIsOpen(!isOpen)
  }
  const handleNavItemClick = () => {
    if (isOpen) {
      setIsOpen(false)
    }
  }

  const handlePageY = () => {
    if (window) {
      if (window.pageYOffset > 100) {
        setNavMini(true)
      } else {
        setNavMini(false)
      }
    }
  }

  const [navMini, setNavMini] = useState(false)
  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", handlePageY)

      return () => {
        window.removeEventListener("scroll", handlePageY)
      }
    }
  }, [])

  return (
    <header
      className={`site_header ${navMini ? "mini-header" : "default-header"}`}
    >
      <div className="site_header__content">
        <div className="logo">
          <Link to="/" onClick={handleNavItemClick}>
            Choose Tap
          </Link>
        </div>

        <Nav isOpen={isOpen} onNavItemClick={handleNavItemClick} />
        <Hamburger isOpen={isOpen} onClick={handleHamburgerOnClick} />
      </div>
    </header>
  )
}
export default Header
