import React from "react"

import loadable from "@loadable/component"
import styles from "./main_layout.module.scss"

import Header from "../../components/header"

const Footer = loadable(() => {
  return import("../../components/footer")
})
import { initAuth } from "../../services/auth"

initAuth()
const MainLayout = (props) => {
  return (
    <>
      <Header />
      <div id="app" className={styles.application}>
        <div className={styles.box}>
          <div>{props.children}</div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default MainLayout
